import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/ipaddress/countries`,
  (_, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      iso_code_alpha3: 'JPN'
    })
  )
);
