import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.post(`${DOMAIN_CONFIG.api}/accounts/login-url-cmp`, (_, res, ctx) => res(
  ctx.status(200),
  ctx.json({
    // eslint-disable-next-line max-len
    login_url: 'https://client-cloudhub-au.zeroclouddev.com/#/login?loginAsAccountHolder=true&cmp_client_id=49846bc2-5573-47db-a9b5-70a8aa231896&cmp_realm=acy&trader_account_id=3143&expire=1667465995&signature=1bc6ccde2861fd6db708acf2f86a6e67d2032e82b40c855e8befdc34fe98deb7'
  })
));
