import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/demo-account-trading-preferences`,
  (_, res, ctx) => res(ctx.status(200), ctx.json([
    {
      id: 'trading_platform',
      type: 'radioButton',
      name: 'Trading Platform',
      placeHolder: '',
      rules: {
        required: {
          value: true,
          message: 'Field is mandatory'
        }
      },
      options: [
        {
          label: 'MT4',
          value: 'MetaTrader4'
        },
        {
          label: 'MT5',
          value: 'MetaTrader5'
        }
      ]
    },
    {
      id: 'base_currency',
      type: 'select',
      name: 'Base Currency',
      placeHolder: 'Please select',
      rules: {
        required: {
          value: true,
          message: 'Field is mandatory'
        }
      },
      options: [
        {
          MetaTrader4: [
            {
              label: 'USD',
              value: 'USD'
            },
            {
              label: 'AUD',
              value: 'AUD'
            },
            {
              label: 'HAY',
              value: 'HAY'
            }
          ],
          MetaTrader5: [
            {
              label: 'AUD',
              value: 'AUD'
            },
            {
              label: 'CAD',
              value: 'CAD'
            }
          ]
        }
      ]
    }
  ]))
);
