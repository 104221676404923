import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.post(
  `${DOMAIN_CONFIG.api}/accounts/:accountId`,
  (_, res, ctx) => res(
    ctx.status(200),
    ctx.json([])
  )
);
