import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/states`,
  (_, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      id: 'state',
      type: 'select',
      name: 'State',
      placeHolder: 'Please Select...',
      rules: {
        required: {
          value: true,
          message: 'Field is mandatory'
        }
      },
      options: [
        {
          value: 'Western Australia',
          label: 'Western Australia'
        },
        {
          value: 'South Australia',
          label: 'South Australia'
        },
        {
          value: 'Northern Territory',
          label: 'Northern Territory'
        },
        {
          value: 'Victoria',
          label: 'Victoria'
        },
        {
          value: 'Tasmania',
          label: 'Tasmania'
        },
        {
          value: 'Queensland',
          label: 'Queensland'
        },
        {
          value: 'New South Wales',
          label: 'New South Wales'
        },
        {
          value: 'Australian Capital Territory',
          label: 'Australian Capital Territory'
        }
      ]
    })
  )
);
