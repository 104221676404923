export default [
  {
    id: 'total_amount_of_investment',
    type: 'select',
    name: 'Total Amount of Investment',
    placeHolder: 'Please select',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: '< 25,000',
        value: '< 25,000'
      },
      {
        label: '25,000 - 80,000',
        value: '25,000 - 80,000'
      },
      {
        label: '80,000 - 150,000',
        value: '80,000 - 150,000'
      },
      {
        label: '> 150,000',
        value: '> 150,000'
      }
    ]
  },
  {
    id: 'trading_platform',
    type: 'radioButton',
    name: 'Trading Platform',
    placeHolder: '',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: 'MT4',
        value: 'MetaTrader4'
      },
      {
        label: 'MT5',
        value: 'MetaTrader5'
      }
    ]
  },
  {
    id: 'funding_currency',
    type: 'select',
    name: 'Funding Currency',
    placeHolder: 'Please select',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        MetaTrader4: [
          {
            label: 'USD',
            value: 'USD'
          },
          {
            label: 'AUD',
            value: 'AUD'
          },
          {
            label: 'NZD',
            value: 'NZD'
          },
          {
            label: 'GBP',
            value: 'GBP'
          },
          {
            label: 'EUR',
            value: 'EUR'
          }
        ],
        MetaTrader5: [
          {
            label: 'AUD',
            value: 'AUD'
          },
          {
            label: 'CAD',
            value: 'CAD'
          },
          {
            label: 'EUR',
            value: 'EUR'
          },
          {
            label: 'GBP',
            value: 'GBP'
          }
        ]
      }
    ]
  },
  {
    id: 'leverage',
    type: 'select',
    name: 'Leverage',
    placeHolder: 'Please select',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        MetaTrader4: [
          {
            label: '50',
            value: '50'
          },
          {
            label: '100',
            value: '100'
          },
          {
            label: '200',
            value: '200'
          },
          {
            label: '300',
            value: '300'
          },
          {
            label: '400',
            value: '400'
          },
          {
            label: '500',
            value: '500'
          },
          {
            label: '600',
            value: '600'
          },
          {
            label: '700',
            value: '700'
          },
          {
            label: '800',
            value: '800'
          },
          {
            label: '900',
            value: '900'
          },
          {
            label: '1000',
            value: '1000'
          }
        ],
        MetaTrader5: [
          {
            label: '50',
            value: '50'
          },
          {
            label: '100',
            value: '100'
          },
          {
            label: '200',
            value: '200'
          },
          {
            label: '300',
            value: '300'
          },
          {
            label: '400',
            value: '400'
          },
          {
            label: '20',
            value: '20'
          },
          {
            label: 'After-update',
            value: 'After-update'
          }
        ]
      }
    ]
  },
  {
    id: 'mt_account_type',
    type: 'radioButton',
    name: 'Account Types',
    placeHolder: '',
    moreButton: {
      name: 'Learn More',
      url: 'https://acy.com/en/platforms/account-types/'
    },
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        MetaTrader4: [
          {
            label: 'STANDARD',
            value: 'STANDARD'
          },
          {
            label: 'PROZERO',
            value: 'PROZERO'
          },
          {
            label: 'After-update',
            value: 'After-update'
          },
          {
            label: 'ISLAMIC',
            value: 'ISLAMIC'
          },
          {
            label: 'abc',
            value: 'abc'
          },
          {
            label: '123',
            value: '123'
          },
          {
            label: '456',
            value: '456'
          },
          {
            label: 'ryantesting',
            value: 'ryantesting'
          },
          {
            label: '!#*(*!#&*!##!dd',
            value: '!#*(*!#&*!##!dd'
          },
          {
            label: 'aa',
            value: 'aa'
          },
          {
            label: 'bb',
            value: 'bb'
          },
          {
            label: 'After-update',
            value: 'After-update'
          }
        ],
        MetaTrader5: [
          {
            label: 'STANDARD',
            value: 'STANDARD'
          },
          {
            label: 'After-update',
            value: 'After-update'
          },
          {
            label: 'ISLAMIC',
            value: 'ISLAMIC'
          },
          {
            label: 'After-update',
            value: 'After-update'
          }
        ]
      }
    ]
  }
];
