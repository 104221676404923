import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export const getAccountByID = rest.get(
  `${DOMAIN_CONFIG.api}/accounts/:accountId`,
  (_, res, ctx) => res(
    ctx.delay(200),
    ctx.status(200),
    ctx.json({
      id: 3570,
      email: 'david50@zerologix.com',
      total_amount_of_investment: '< 25,000',
      trading_platform: 'MetaTrader5',
      funding_currency: 'range1',
      leverage: '300',
      mt_account_type: 'prozero',
      shareholders: [
        {
          first_name: 'ssdaf',
          last_name: 's2',
          middle_name: null,
          gender: 'Female',
          nationality: 'AUS',
          photo_id_number: 's6',
          email: 's7',
          mobile: '886',
          dob: '1990-08-27',
          country: 'AUS',
          residential_address: 's11',
          city: '',
          zip_code: '123',
          state: '',
          share_percentage: ''
        },
        {
          first_name: 'ssdaf 2',
          last_name: 's2',
          middle_name: null,
          gender: 'Female',
          nationality: 'AUS',
          photo_id_number: 's6',
          email: 's7',
          mobile: '886',
          dob: '1990-08-27',
          country: 'AUS',
          residential_address: 's11',
          city: '',
          zip_code: '123',
          state: '',
          share_percentage: ''
        }
      ],
      directors: [
        {
          first_name: 'Huang',
          last_name: 'Willy',
          middle_name: null,
          gender: 'Male',
          nationality: 'AUS',
          photo_id_number: 's6',
          email: 's7',
          mobile: '886',
          dob: '1990-08-27',
          country: 'AUS',
          residential_address: 's11',
          city: '',
          zip_code: '123',
          state: ''
        }
      ],
      employment_status: 'unemployed',
      industry: 'Professional, Scientific and Technical Services',
      position: null,
      annual_income: '30,000 - 79,999',
      source_of_funds: '',
      first_name: 'John',
      middle_name: null,
      last_name: 'WFH',
      mobile: '61321312',
      gender: 'Male',
      dob: '1990-08-27',
      residential_address: '12312',
      city: 'Sydney',
      zip_code: '77540',
      state: 'NSW',
      country: 'AUS',
      nationality: 'AUS',
      photo_id_number: '12321321',
      terms_and_conditions: null,
      form_step: 'experience',
      jumio_verified: 0,
      ai_pass: 0,
      require_poa: 0,
      resource_account_id: '0a0003c1-8536-1d68-8185-575a7e030050',
      rg227_test_id: null,
      question_0: null,
      question_1: null,
      question_2: null,
      question_3: null,
      question_4: null,
      question_5: null,
      question_6: null,
      question_7: null,
      question_8: null,
      question_9: null
    })
  )
);

export const getRejectAccountByID = rest.get(
  `${DOMAIN_CONFIG.api}/accounts/:accountId`,
  (_, res, ctx) => res(
    ctx.delay(200),
    ctx.status(200),
    ctx.json({
      id: 3139,
      email: 'syd-wfh-ray-test-29@zerologix.com',
      total_amount_of_investment: '80,000 - 150,000',
      trading_platform: 'MetaTrader4',
      funding_currency: 'AUD',
      leverage: '100',
      mt_account_type: 'abc',
      employment_status: 'employed',
      industry: 'Electricity, Gas, Water and Waste Services',
      position: 'other',
      source_of_funds: 'savings',
      annual_income: '> 150,000',
      first_name: 'Will',
      middle_name: '',
      last_name: 'Smithh',
      mobile: '61321312',
      gender: 'Male',
      dob: '1990-08-27',
      residential_address: '12312111',
      city: 'Sydney',
      zip_code: '77540',
      state: 'Northern Territory',
      country: 'AUS',
      nationality: 'AUS',
      photo_id_number: '12321321',
      terms_and_conditions: null,
      onboarding_type: 0,
      form_step: 'trading_employment',
      ai_pass: 0,
      id_front: '',
      jumio_verified: 0,
      require_poa: 0,
      resource_account_id: '0a0003c1-8536-1d68-8185-575a7e030050',
      rg227_test_id: null,
      question_0: 'a0-1',
      question_1: 'a1-2',
      question_2: 'a2-1',
      question_3: 'a3-3',
      question_4: 'a4-1',
      question_5: 'a5-3',
      question_6: 'a6-2',
      question_7: 'a7-1',
      question_8: 'a8-1',
      question_9: 'a9-1',
      read_product_disclosure_statement: true,
      read_financial_service_guide: true,
      read_account_opening_terms_and_conditions: true,
      read_privacy_policy_statement: true,
      read_target_market_determination: true
    })
  )
);
