import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/accounts/:accountId/reviews`,
  (_, res, ctx) => res(
    ctx.delay(1000),
    ctx.status(200),
    ctx.json({
      corporate_contact_person_details: [
        {
          last_name: {
            warning_message: ['Last name does not match the first name in ID submitted'],
            warning_value: 'vv'
          },
          residential_address: {
            warning_message: ['message6', 'message7', 'message8'],
            warning_value: 'vv'
          }
          // nationality: {
          //   warning_message: ['message6', 'message7', 'message8'],
          //   warning_value: 'AUS'
          // },
          // mobile: {
          //   warning_message: ['message6', 'message7', 'message8'],
          //   warning_value: '61111111'
          // },
          // dob: {
          //   warning_message: ['message6', 'message7', 'message8'],
          //   warning_value: '2005-06-14'
          // }
        }
      ],
      corporate_company_details_trading_preference: [
        {
          company: {
            company_address: {
              warning_message: ['message6', 'message7', 'message8'],
              warning_value: 'vv'
            }
          }
        }
      ],
      corporate_shareholders_directors: [
        {
          shareholders: [
            {
              last_name: {
                warning_message: ['message6', 'message7', 'message8'],
                warning_value: 'vv'
              },
              mobile: {
                warning_message: ['message6', 'message7', 'message8'],
                warning_value: '6111'
              }

            },
            {
              dob: {
                warning_message: ['message6', 'message7', 'message8'],
                warning_value: '2005-06-07'
              },
              residential_address: {
                warning_message: ['message6', 'message7', 'message8'],
                warning_value: 'ff'
              }
            }
          ],
          directors: [
            {},
            {
              zip_code: {
                warning_message: ['message6', 'message7', 'message8'],
                warning_value: 'yy'
              },
              residential_address: {
                warning_message: ['message6', 'message7', 'message8'],
                warning_value: 'yy'
              }
            }
          ]
        }
      ],
      // corporate_confirm_id: [
      //   {
      //     id_front: {
      //       warning_message: ['documents warning message'],
      //       warning_value: ''
      //     }
      //   }
      // ],
      corporate_confirm_id: [
        {
          company: {
            documents: [
              {
                document_id: 12809,
                type: 'RC',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688005549lalocif816@edulena.com2023-06-29_022549am_RC.png'
              },
              {
                document_id: 12808,
                type: 'POA-Cor',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688008051lalocif816@edulena.com2023-06-29_030731am_POA-Cor.png'
              }
            ]
          },

          shareholders: [
            {
              shareholder_id: 2115,
              documents: [
                {
                  document_id: 12814,
                  type: 'ID-Front-S',
                  warning_message: ['aaadocuments warning message1916'],
                  warning_value: '/images/acyform/202306/1687930099lalocif816@edulena.com2023-06-28_052819am_ID-Front-S.png'
                }
                // {
                //   document_id: 12622,
                //   type: 'ID-Back-S',
                //   warning_message: ['bbbdocuments warning message1916'],
                //   warning_value: ''
                // }
              ]
            },
            {
              shareholder_id: 2116,
              documents: [
                // {
                //   document_id: 12623,
                //   type: 'ID-Front-S',
                //   warning_message: ['ccccdocuments warning message'],
                //   warning_value: ''
                // },
                {
                  document_id: 12818,
                  type: 'ID-Back-S',
                  warning_message: ['ddddddocuments warning message'],
                  warning_value: '/images/acyform/202306/1687930125lalocif816@edulena.com2023-06-28_052845am_ID-Back-S.png'
                }
              ]
            }
          ],

          directors: [
            {
              director_id: 330,
              documents: [
                {
                  document_id: 12824,
                  type: 'ID-Back-D',
                  warning_message: ['444documents warning message'],
                  warning_value: '/images/acyform/202306/1687930158lalocif816@edulena.com2023-06-28_052918am_ID-Back-D.png'
                }
              ]
            }
          ]

          // // If there is no shareholder and director as same contact person
          // contactPerson: {
          //   documents: [
          //     {
          //       document_id: 12621,
          //       type: 'ID-Front-C',
          //       warning_message: ['documents warning message'],
          //       warning_value: ''
          //     },
          //     {
          //       document_id: 12622,
          //       type: 'COP',
          //       warning_message: ['documents warning message'],
          //       warning_value: ''
          //     }
          //   ]
          // }
        }
      ]
    })
  )
);
