/* eslint-disable max-len */
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(`${DOMAIN_CONFIG.api}/rg227-tests-practice`, (req, res, ctx) => res(
  ctx.delay(2000),
  ctx.status(200),
  ctx.json(
    {
      answers: {
        question_0: 'a0-1',
        question_1: 'a1-1',
        question_2: 'a2-2',
        question_3: 'a3-3',
        question_4: 'a4-1'
      },
      questions: [
        {
          id: 'question_0',
          type: 'radio',
          name: '1. Which of the following scenarios regarding Leverage is correct???',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $3,000',
              value: 'a0-1'
            },
            {
              label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $40,000',
              value: 'a0-2'
            },
            {
              label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $300,000',
              value: 'a0-3'
            }
          ]
        }, {
          id: 'question_1',
          type: 'radioButton',
          name: '2. ACY Securities MT4/MT5 charts by default only show Bid price.',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'True',
              value: 'a1-1'
            },
            {
              label: 'False',
              value: 'a1-2'
            }
          ]
        }, {
          id: 'question_2',
          type: 'radioButton',
          name: '3. Trading with leverage in Forex or any OTC Derivative product, there may be a higher level of associated risk.',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'True',
              value: 'a2-1'
            },
            {
              label: 'False',
              value: 'a2-2'
            }
          ]
        }, {
          id: 'question_3',
          type: 'radio',
          name: '4. You have recently begun trading in Margin FX and you do not fully understand the Risk Management concepts, the best course of action would be:',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'Book myself into training/education',
              value: 'a3-1'
            },
            {
              label: 'Download a demo/practice account in order to get some experience',
              value: 'a3-2'
            },
            {
              label: 'Seek independent professional advice',
              value: 'a3-3'
            },
            {
              label: 'All of the above',
              value: 'a3-4'
            }
          ]
        }, {
          id: 'question_4',
          type: 'radioButton',
          name: '5. When I have open positions, it\'s my own responsibility to monitor my positions and ensure I have enough fund in my account to cover the margin obligation.',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'True',
              value: 'a4-1'
            },
            {
              label: 'False',
              value: 'a4-2'
            }
          ]
        }
      ]
    }
  )

));
