import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/footer`,
  (_, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      // eslint-disable-next-line max-len
      footer: '<p style="color: red">Test RISK WARNING: CFDs are leveraged derivative products and carry a high level of risk. It is possible to lose all your initial investment and you may be required to make further payments. These products may not be suitable for all clients, so please review our disclosure documents and seek independent advice to determine whether it is right for you. Any information provided does not consider your objectives, needs or financial situation.ACY Securities Pty Ltd (&ldquo;ACY AU&rdquo;) is licensed and regulated by the Australian Securities and Investments Commission (AFSL 403863).ACY Capital Australia Limited (&ldquo;ACY LTD&rdquo;), is licensed and regulated by the Vanuatu Financial Services Commission (VFSC 012868).&nbsp;</p>'
    })
  )
);
