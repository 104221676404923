import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export const getIncompleteAccount = rest.get(`${DOMAIN_CONFIG.api}/incomplete-accounts`, (req, res, ctx) => res(
  ctx.delay(200),
  ctx.status(200),
  ctx.json([{
    id: 3426,
    email: 'incomplete@test.com',
    total_amount_of_investment: '< 25,000',
    trading_platform: 'MetaTrader4',
    funding_currency: 'USD',
    leverage: '200',
    mt_account_type: 'AU_after-update',
    employment_status: 'student',
    industry: 'Manufacturing',
    position: 'entry_level',
    annual_income: '30,000 - 79,999',
    source_of_funds: 'income_from_employment',
    first_name: 'incomplete first name',
    middle_name: 'incomplete middle name',
    last_name: 'incomplete last name',
    mobile: '612312321321',
    gender: 'Male',
    dob: '2004-11-03',
    residential_address: 'incomplete address',
    city: 'incomplete city',
    zip_code: '1231',
    state: 'Western Australia',
    country: 'AUS',
    nationality: 'AUS',
    photo_id_number: '123121',
    terms_and_conditions: null,
    form_step: 'experience',
    jumio_verified: 0,
    ai_pass: 0,
    require_poa: 0,
    resource_account_id: '0a00030e-8489-1be3-8184-a874a54e00b4',
    rg227_test_id: 3747,
    question_0: null,
    question_1: null,
    question_2: null,
    question_3: null,
    question_4: null,
    question_5: null,
    question_6: null,
    question_7: null,
    question_8: null,
    question_9: null
  }])
));

export const getEmptyIncompleteAccount = rest.get(`${DOMAIN_CONFIG.api}/incomplete-accounts`, (req, res, ctx) => res(
  ctx.delay(200),
  ctx.status(200),
  ctx.json([])
));
