/* eslint-disable max-len */
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(`${DOMAIN_CONFIG.api}/accounts/:accountId/rg227-tests`, (req, res, ctx) => res(
  ctx.delay(2000),
  ctx.status(200),
  ctx.json([
    {
      testId: 3512,
      status: 'not_submitted',
      score: null,
      q_language: 'en',
      setting: {
        pass_score: 90
      },
      questions: [
        {
          id: 'question_0',
          type: 'radio',
          name: '1. Which of the following scenarios regarding Leverage is correct???',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $3,000',
              value: 'a0-1'
            },
            {
              label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $40,000',
              value: 'a0-2'
            },
            {
              label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $300,000',
              value: 'a0-3'
            }
          ]
        }, {
          id: 'question_1',
          type: 'radioButton',
          name: '2. ACY Securities MT4/MT5 charts by default only show Bid price.',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'True',
              value: 'a1-1'
            },
            {
              label: 'False',
              value: 'a1-2'
            }
          ]
        }, {
          id: 'question_2',
          type: 'radioButton',
          name: '3. Trading with leverage in Forex or any OTC Derivative product, there may be a higher level of associated risk.',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'True',
              value: 'a2-1'
            },
            {
              label: 'False',
              value: 'a2-2'
            }
          ]
        }, {
          id: 'question_3',
          type: 'radio',
          name: '4. You have recently begun trading in Margin FX and you do not fully understand the Risk Management concepts, the best course of action would be:',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'Book myself into training/education',
              value: 'a3-1'
            },
            {
              label: 'Download a demo/practice account in order to get some experience',
              value: 'a3-2'
            },
            {
              label: 'Seek independent professional advice',
              value: 'a3-3'
            },
            {
              label: 'All of the above',
              value: 'a3-4'
            }
          ]
        }, {
          id: 'question_4',
          type: 'radioButton',
          name: '5. When I have open positions, it\'s my own responsibility to monitor my positions and ensure I have enough fund in my account to cover the margin obligation.',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'True',
              value: 'a4-1'
            },
            {
              label: 'False',
              value: 'a4-2'
            }
          ]
        }, {
          id: 'question_5',
          type: 'radio',
          name: '6. When you buy a CFD on future contract of crude oil, what is the typical delivery time?',
          placeHolder: '',
          required: true,
          data: [
            {
              label: '3 Days',
              value: 'a5-1'
            },
            {
              label: '5 Days',
              value: 'a5-2'
            },
            {
              label: 'CFD’s do not entitle you to ownership rights of the underlying asset',
              value: 'a5-3'
            }
          ]
        }, {
          id: 'question_6',
          type: 'radio',
          name: '7. Which of these methods is a better option to reduce the downside risk on your trade?',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'Place a stop loss',
              value: 'a6-1'
            },
            {
              label: 'Trade without a stop loss',
              value: 'a6-2'
            }
          ]
        }, {
          id: 'question_7',
          type: 'radio',
          name: '8. Which of the following statements best describe high volatility?',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'When price increases greatly',
              value: 'a7-1'
            },
            {
              label: 'When price fluctuates in a very wide range within a short period of time',
              value: 'a7-2'
            },
            {
              label: 'A term used to describe a price decrease',
              value: 'a7-3'
            }
          ]
        }, {
          id: 'question_8',
          type: 'radio',
          name: '9. What is your reason for trading CFDs?',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'Speculation and or Hedging',
              value: 'a8-1'
            },
            {
              label: 'CFDs are not an investment product. I decide not to continue this application.',
              value: 'a8-2'
            }
          ]
        }, {
          id: 'question_9',
          type: 'radio',
          name: '10. Would losing your trading capital have a material impact on your standard of living?',
          placeHolder: '',
          required: true,
          data: [
            {
              label: 'No',
              value: 'a9-1'
            },
            {
              label: 'Yes. CFDs are not suitable for me. I decide not to continue this application.',
              value: 'a9-2'
            }
          ]
        }
      ],
      submit_data: null
    }
  ])

));
