import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/demo-accounts`,
  (_, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      active: [
        {
          account_name: 'Sy01MT4',
          server_name: 'Server name 1',
          total_balance: '2,000.00 AUD',
          account_type: 'Demo',
          funds: '20,000.00',
          account_number: '12345678',
          trading_platform: 'MT4',
          days_left: '2 months left'
        }
      ],
      expired: [
        {
          account_name: 'Sy02MT4',
          server_name: 'Server name 2',
          total_balance: '1,000.00 AUD',
          account_type: 'Demo',
          funds: '20,000.00',
          account_number: '12345678',
          trading_platform: 'MT4',
          days_left: 'expired'
        }
      ]
    })
  )
);
