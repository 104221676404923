import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(`${DOMAIN_CONFIG.api}/transactions`, (_, res, ctx) => res(
  ctx.status(200),
  ctx.json({
    workflow_id: 'e1cf996d-6ae4-4086-86be-db671e7b8a75',
    workflow_status: 'PROCESSED',
    account_id: '8672d967-2c0c-4367-91bd-ce140fd9c28f',
    decision: 'WARNING',
    risk: 22,
    user_details: {
      first_name: '',
      last_name: '',
      dob: '',
      state: '',
      address: {
        line1: '4th Blvd.',
        line2: '',
        country: 'Taiwan',
        postalCode: '114',
        subdivision: 'subdivision',
        city: 'Taipei',
        formattedAddress: ''
      },
      gender: '',
      nationality: ''
    }
  })
));
