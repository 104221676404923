import {
  getAccounts, getRejectAccounts, getEmptyAccount
} from 'mocks/rest/accounts/accounts';
import transactions from 'mocks/rest/accounts/transactions';
import getAccountPortalLoginURL from 'mocks/rest/accounts/getAccountPortalLoginURL';
import personalDetails from 'mocks/rest/accounts/personalDetails';
import getStates from 'mocks/rest/accounts/getStates';
import getExperienceForm from 'mocks/rest/accounts/getExperienceForm';
import getExperiencePracticeForm from 'mocks/rest/accounts/getExperiencePracticeForm';
import getShareholdersDirectors from 'mocks/rest/accounts/getShareholdersDirectors';
import { getIncompleteAccount } from 'mocks/rest/accounts/getIncompleteAccount';
import getCompanyDetails from 'mocks/rest/accounts/getCompanyDetails';
import getRejectMessages from 'mocks/rest/accounts/getRejectMessages';
import updateAccount from 'mocks/rest/accounts/updateAccount';
import { getAccountByID, getRejectAccountByID } from 'mocks/rest/accounts/getAccountByID';
import getDemoTradingFields from 'mocks/rest/accounts/getDemoTradingFields';
import getDemoAccounts from 'mocks/rest/accounts/getDemoAccounts';
import getFooter from 'mocks/rest/accounts/getFooter';
import getCorporateReview from 'mocks/rest/accounts/getCorporateReview';
import getJointReview from 'mocks/rest/accounts/getJointReview';
import tradingPreferences from './accounts/tradingPreferences';
import getCountryByIP from './user/getCountryByIP';

export const handlers = [
  getAccounts,
  getEmptyAccount,
  transactions,
  getAccountPortalLoginURL,
  getExperienceForm,
  getExperiencePracticeForm,
  getCompanyDetails,
  getShareholdersDirectors,
  personalDetails,
  getStates,
  getIncompleteAccount,
  updateAccount,
  getAccountByID,
  getDemoTradingFields,
  getDemoAccounts,
  getFooter,
  tradingPreferences,
  getCountryByIP,
  getCorporateReview,
  getJointReview
];

export const rejectAccountHandlers = [
  getRejectAccounts,
  getEmptyAccount,
  transactions,
  getAccountPortalLoginURL,
  getExperienceForm,
  getExperiencePracticeForm,
  getCompanyDetails,
  getShareholdersDirectors,
  personalDetails,
  getStates,
  getRejectMessages,
  updateAccount,
  getRejectAccountByID,
  getDemoTradingFields,
  getDemoAccounts,
  getFooter,
  tradingPreferences,
  getCountryByIP,
  getCorporateReview,
  getJointReview
];
