/* eslint-disable max-len */
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/accounts/:accountId/rg227-tests`,
  (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json([
      {
        id: 'company_name',
        type: 'input',
        name: 'Company Name',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      },
      {
        id: 'country_of_company',
        type: 'select',
        name: 'Country of Company',
        placeHolder: 'Please select',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null,
        options: [
          {
            label: 'Australia',
            value: 'AUS'
          },
          {
            label: 'China',
            value: 'CHN'
          },
          {
            label: 'Indonesia',
            value: 'IDN'
          },
          {
            label: 'Viet Nam',
            value: 'VNM'
          },
          {
            label: 'United Arab Emirates',
            value: 'ARE'
          },
          {
            label: 'Hong Kong',
            value: 'HKG'
          },
          {
            label: 'Macao',
            value: 'MAC'
          },
          {
            label: 'Taiwan',
            value: 'TWN'
          },
          {
            label: 'Singapore',
            value: 'SGP'
          },
          {
            label: 'Malaysia',
            value: 'MYS'
          },
          {
            label: 'Egypt',
            value: 'EGY'
          },
          {
            label: 'Afghanistan',
            value: 'AFG'
          },
          {
            label: 'Albania',
            value: 'ALB'
          },
          {
            label: 'Algeria',
            value: 'DZA'
          },
          {
            label: 'Andorra',
            value: 'AND'
          },
          {
            label: 'Angola',
            value: 'AGO'
          },
          {
            label: 'Antigua and Barbuda',
            value: 'ATG'
          },
          {
            label: 'Argentina',
            value: 'ARG'
          },
          {
            label: 'Armenia',
            value: 'ARM'
          },
          {
            label: 'Austria',
            value: 'AUT'
          },
          {
            label: 'Azerbaijan',
            value: 'AZE'
          },
          {
            label: 'Bahamas',
            value: 'BHS'
          },
          {
            label: 'Bahrain',
            value: 'BHR'
          },
          {
            label: 'Bangladesh',
            value: 'BGD'
          },
          {
            label: 'Barbados',
            value: 'BRB'
          },
          {
            label: 'Belarus',
            value: 'BLR'
          },
          {
            label: 'Belgium',
            value: 'BEL'
          },
          {
            label: 'Belize',
            value: 'BLZ'
          },
          {
            label: 'Benin',
            value: 'BEN'
          },
          {
            label: 'Bhutan',
            value: 'BTN'
          },
          {
            label: 'Bolivia (Plurinational State of)',
            value: 'BOL'
          },
          {
            label: 'Bosnia and Herzegovina',
            value: 'BIH'
          },
          {
            label: 'Botswana',
            value: 'BWA'
          },
          {
            label: 'Brazil',
            value: 'BRA'
          },
          {
            label: 'Brunei Darussalam',
            value: 'BRN'
          },
          {
            label: 'Bulgaria',
            value: 'BGR'
          },
          {
            label: 'Burkina Faso',
            value: 'BFA'
          },
          {
            label: 'Burundi',
            value: 'BDI'
          },
          {
            label: 'Cabo Verde',
            value: 'CPV'
          },
          {
            label: 'Cambodia',
            value: 'KHM'
          },
          {
            label: 'Cameroon',
            value: 'CMR'
          },
          {
            label: 'Canada',
            value: 'CAN'
          },
          {
            label: 'Central African Republic',
            value: 'CAF'
          },
          {
            label: 'Chad',
            value: 'TCD'
          },
          {
            label: 'Chile',
            value: 'CHL'
          },
          {
            label: 'Colombia',
            value: 'COL'
          },
          {
            label: 'Comoros',
            value: 'COM'
          },
          {
            label: 'Congo',
            value: 'COG'
          },
          {
            label: 'Congo, Democratic Republic of the',
            value: 'COD'
          },
          {
            label: 'Costa Rica',
            value: 'CRI'
          },
          {
            label: "C\u00f4te d'Ivoire",
            value: 'CIV'
          },
          {
            label: 'Croatia',
            value: 'HRV'
          },
          {
            label: 'Cuba',
            value: 'CUB'
          },
          {
            label: 'Cyprus',
            value: 'CYP'
          },
          {
            label: 'Czechia',
            value: 'CZE'
          },
          {
            label: 'Denmark',
            value: 'DNK'
          },
          {
            label: 'Djibouti',
            value: 'DJI'
          },
          {
            label: 'Dominica',
            value: 'DMA'
          },
          {
            label: 'Dominican Republic',
            value: 'DOM'
          },
          {
            label: 'Ecuador',
            value: 'ECU'
          },
          {
            label: 'El Salvador',
            value: 'SLV'
          },
          {
            label: 'Equatorial Guinea',
            value: 'GNQ'
          },
          {
            label: 'Eritrea',
            value: 'ERI'
          },
          {
            label: 'Estonia',
            value: 'EST'
          },
          {
            label: 'Eswatini',
            value: 'SWZ'
          },
          {
            label: 'Ethiopia',
            value: 'ETH'
          },
          {
            label: 'Fiji',
            value: 'FJI'
          },
          {
            label: 'Finland',
            value: 'FIN'
          },
          {
            label: 'France',
            value: 'FRA'
          },
          {
            label: 'Gabon',
            value: 'GAB'
          },
          {
            label: 'Gambia',
            value: 'GMB'
          },
          {
            label: 'Georgia',
            value: 'GEO'
          },
          {
            label: 'Germany',
            value: 'DEU'
          },
          {
            label: 'Ghana',
            value: 'GHA'
          },
          {
            label: 'Greece',
            value: 'GRC'
          },
          {
            label: 'Grenada',
            value: 'GRD'
          },
          {
            label: 'Guatemala',
            value: 'GTM'
          },
          {
            label: 'Guinea',
            value: 'GIN'
          },
          {
            label: 'Guinea-Bissau',
            value: 'GNB'
          },
          {
            label: 'Guyana',
            value: 'GUY'
          },
          {
            label: 'Haiti',
            value: 'HTI'
          },
          {
            label: 'Honduras',
            value: 'HND'
          },
          {
            label: 'Hungary',
            value: 'HUN'
          },
          {
            label: 'Iceland',
            value: 'ISL'
          },
          {
            label: 'India',
            value: 'IND'
          },
          {
            label: 'Iran (Islamic Republic of)',
            value: 'IRN'
          },
          {
            label: 'Iraq',
            value: 'IRQ'
          },
          {
            label: 'Ireland',
            value: 'IRL'
          },
          {
            label: 'Israel',
            value: 'ISR'
          },
          {
            label: 'Italy',
            value: 'ITA'
          },
          {
            label: 'Jamaica',
            value: 'JAM'
          },
          {
            label: 'Japan',
            value: 'JPN'
          },
          {
            label: 'Jordan',
            value: 'JOR'
          },
          {
            label: 'Kazakhstan',
            value: 'KAZ'
          },
          {
            label: 'Kenya',
            value: 'KEN'
          },
          {
            label: 'Kiribati',
            value: 'KIR'
          },
          {
            label: "Korea (Democratic People's Republic of)",
            value: 'PRK'
          },
          {
            label: 'Korea, Republic of',
            value: 'KOR'
          },
          {
            label: 'Kuwait',
            value: 'KWT'
          },
          {
            label: 'Kyrgyzstan',
            value: 'KGZ'
          },
          {
            label: "Lao People's Democratic Republic",
            value: 'LAO'
          },
          {
            label: 'Latvia',
            value: 'LVA'
          },
          {
            label: 'Lebanon',
            value: 'LBN'
          },
          {
            label: 'Lesotho',
            value: 'LSO'
          },
          {
            label: 'Liberia',
            value: 'LBR'
          },
          {
            label: 'Libya',
            value: 'LBY'
          },
          {
            label: 'Liechtenstein',
            value: 'LIE'
          },
          {
            label: 'Lithuania',
            value: 'LTU'
          },
          {
            label: 'Luxembourg',
            value: 'LUX'
          },
          {
            label: 'Madagascar',
            value: 'MDG'
          },
          {
            label: 'Malawi',
            value: 'MWI'
          },
          {
            label: 'Maldives',
            value: 'MDV'
          },
          {
            label: 'Mali',
            value: 'MLI'
          },
          {
            label: 'Malta',
            value: 'MLT'
          },
          {
            label: 'Marshall Islands',
            value: 'MHL'
          },
          {
            label: 'Mauritania',
            value: 'MRT'
          },
          {
            label: 'Mauritius',
            value: 'MUS'
          },
          {
            label: 'Mexico',
            value: 'MEX'
          },
          {
            label: 'Micronesia (Federated States of)',
            value: 'FSM'
          },
          {
            label: 'Moldova, Republic of',
            value: 'MDA'
          },
          {
            label: 'Monaco',
            value: 'MCO'
          },
          {
            label: 'Mongolia',
            value: 'MNG'
          },
          {
            label: 'Montenegro',
            value: 'MNE'
          },
          {
            label: 'Morocco',
            value: 'MAR'
          },
          {
            label: 'Mozambique',
            value: 'MOZ'
          },
          {
            label: 'Myanmar',
            value: 'MMR'
          },
          {
            label: 'Namibia',
            value: 'NAM'
          },
          {
            label: 'Nauru',
            value: 'NRU'
          },
          {
            label: 'Nepal',
            value: 'NPL'
          },
          {
            label: 'Netherlands',
            value: 'NLD'
          },
          {
            label: 'New Zealand',
            value: 'NZL'
          },
          {
            label: 'Nicaragua',
            value: 'NIC'
          },
          {
            label: 'Niger',
            value: 'NER'
          },
          {
            label: 'Nigeria',
            value: 'NGA'
          },
          {
            label: 'North Macedonia',
            value: 'MKD'
          },
          {
            label: 'Norway',
            value: 'NOR'
          },
          {
            label: 'Oman',
            value: 'OMN'
          },
          {
            label: 'Pakistan',
            value: 'PAK'
          },
          {
            label: 'Palau',
            value: 'PLW'
          },
          {
            label: 'Panama',
            value: 'PAN'
          },
          {
            label: 'Papua New Guinea',
            value: 'PNG'
          },
          {
            label: 'Paraguay',
            value: 'PRY'
          },
          {
            label: 'Peru',
            value: 'PER'
          },
          {
            label: 'Philippines',
            value: 'PHL'
          },
          {
            label: 'Poland',
            value: 'POL'
          },
          {
            label: 'Portugal',
            value: 'PRT'
          },
          {
            label: 'Qatar',
            value: 'QAT'
          },
          {
            label: 'Romania',
            value: 'ROU'
          },
          {
            label: 'Russian Federation',
            value: 'RUS'
          },
          {
            label: 'Rwanda',
            value: 'RWA'
          },
          {
            label: 'Saint Kitts and Nevis',
            value: 'KNA'
          },
          {
            label: 'Saint Lucia',
            value: 'LCA'
          },
          {
            label: 'Saint Vincent and the Grenadines',
            value: 'VCT'
          },
          {
            label: 'Samoa',
            value: 'WSM'
          },
          {
            label: 'San Marino',
            value: 'SMR'
          },
          {
            label: 'Sao Tome and Principe',
            value: 'STP'
          },
          {
            label: 'Saudi Arabia',
            value: 'SAU'
          },
          {
            label: 'Senegal',
            value: 'SEN'
          },
          {
            label: 'Serbia',
            value: 'SRB'
          },
          {
            label: 'Seychelles',
            value: 'SYC'
          },
          {
            label: 'Sierra Leone',
            value: 'SLE'
          },
          {
            label: 'Slovakia',
            value: 'SVK'
          },
          {
            label: 'Slovenia',
            value: 'SVN'
          },
          {
            label: 'Solomon Islands',
            value: 'SLB'
          },
          {
            label: 'Somalia',
            value: 'SOM'
          },
          {
            label: 'South Africa',
            value: 'ZAF'
          },
          {
            label: 'South Sudan',
            value: 'SSD'
          },
          {
            label: 'Spain',
            value: 'ESP'
          },
          {
            label: 'Sri Lanka',
            value: 'LKA'
          },
          {
            label: 'Sudan',
            value: 'SDN'
          },
          {
            label: 'Suriname',
            value: 'SUR'
          },
          {
            label: 'Sweden',
            value: 'SWE'
          },
          {
            label: 'Switzerland',
            value: 'CHE'
          },
          {
            label: 'Syrian Arab Republic',
            value: 'SYR'
          },
          {
            label: 'Tajikistan',
            value: 'TJK'
          },
          {
            label: 'Tanzania, United Republic of',
            value: 'TZA'
          },
          {
            label: 'Thailand',
            value: 'THA'
          },
          {
            label: 'Timor-Leste',
            value: 'TLS'
          },
          {
            label: 'Togo',
            value: 'TGO'
          },
          {
            label: 'Tonga',
            value: 'TON'
          },
          {
            label: 'Trinidad and Tobago',
            value: 'TTO'
          },
          {
            label: 'Tunisia',
            value: 'TUN'
          },
          {
            label: 'Turkey',
            value: 'TUR'
          },
          {
            label: 'Turkmenistan',
            value: 'TKM'
          },
          {
            label: 'Tuvalu',
            value: 'TUV'
          },
          {
            label: 'Uganda',
            value: 'UGA'
          },
          {
            label: 'Ukraine',
            value: 'UKR'
          },
          {
            label: 'United Kingdom of Great Britain and Northern Ireland',
            value: 'GBR'
          },
          {
            label: 'United States of America',
            value: 'USA'
          },
          {
            label: 'Uruguay',
            value: 'URY'
          },
          {
            label: 'Uzbekistan',
            value: 'UZB'
          },
          {
            label: 'Vanuatu',
            value: 'VUT'
          },
          {
            label: 'Venezuela (Bolivarian Republic of)',
            value: 'VEN'
          },
          {
            label: 'Yemen',
            value: 'YEM'
          },
          {
            label: 'Zambia',
            value: 'ZMB'
          },
          {
            label: 'Zimbabwe',
            value: 'ZWE'
          },
          {
            label: 'Puerto Rico',
            value: 'PRI'
          },
          {
            label: 'Palestine',
            value: 'PSE'
          }
        ]
      },
      {
        id: 'business_registration_number',
        type: 'input',
        name: 'Business Registration Number',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      },
      {
        id: 'company_type',
        type: 'select',
        name: 'Company Type',
        placeHolder: 'Please select',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null,
        options: []
      },
      {
        id: 'legal_entity_identifier',
        type: 'input',
        name: 'Legal Entity Identifier',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      },
      {
        id: 'date_of_incorporation',
        type: 'date',
        name: 'Date of Incorporation',
        placeHolder: 'Date of Incorporation',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      },
      {
        id: 'company_address',
        type: 'input',
        name: 'Company Address',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      },
      {
        id: 'company_city',
        type: 'input',
        name: 'Company City',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      },
      {
        id: 'company_state_province',
        type: 'input',
        name: 'Company State/Province',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      },
      {
        id: 'regulate_state',
        type: 'select',
        name: 'Are you regulated?',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null,
        options: [
          {
            label: 'Yes',
            value: 'yes'
          },
          {
            label: 'No',
            value: 'no'
          }
        ]
      },
      {
        id: 'regulated_country',
        type: 'select',
        name: 'Regulated Country',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null,
        options: []
      },
      {
        id: 'regulate_number',
        type: 'input',
        name: 'Regulated Number',
        placeHolder: '',
        rules: {
          required: {
            value: true,
            message: 'Field is mandatory'
          }
        },
        moreButton: null
      }
    ])
  )
);
