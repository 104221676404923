import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export default rest.get(
  `${DOMAIN_CONFIG.api}/accounts/:accountId/reviews`,
  (_, res, ctx) => res(
    ctx.delay(1000),
    ctx.status(200),
    ctx.json({
      joint_primary_account_holder: [
        {
          first_name: {
            warning_message: ['First name does not match the first name in ID submitted'],
            warning_value: 'firstName'
          },
          last_name: {
            warning_message: ['Last name does not match the last name in ID submitted'],
            warning_value: 'lastName'
          },
          residential_address: {
            warning_message: ['message6', 'message7', 'message8'],
            warning_value: 'address'
          }
        }
      ],
      joint_secondary_account_holder: [
        {
          first_name: {
            warning_message: ['First name does not match the first name in ID submitted'],
            warning_value: 'firstName'
          },
          last_name: {
            warning_message: ['Last name does not match the last name in ID submitted'],
            warning_value: 'lastName'
          },
          residential_address: {
            warning_message: ['message6', 'message7', 'message8'],
            warning_value: 'address'
          }
        }
      ],
      joint_confirm_id: [
        {
          primary_account_holder: {
            documents: [
              {
                document_id: 12809,
                type: 'ID-Front-J-Pr',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688005549lalocif816@edulena.com2023-06-29_022549am_RC.png'
              },
              {
                document_id: 12808,
                type: 'ID-Back-J-Pr',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688008051lalocif816@edulena.com2023-06-29_030731am_POA-Cor.png'
              },
              {
                document_id: 12809,
                type: 'POA-J-Pr',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688008051lalocif816@edulena.com2023-06-29_030731am_POA-Cor.png'
              }
            ]
          },
          secondary_account_holder: {
            documents: [
              {
                document_id: 12809,
                type: 'ID-Front-J-Se',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688005549lalocif816@edulena.com2023-06-29_022549am_RC.png'
              },
              {
                document_id: 12808,
                type: 'ID-Back-J-Se',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688008051lalocif816@edulena.com2023-06-29_030731am_POA-Cor.png'
              },
              {
                document_id: 12809,
                type: 'POA-J-Se',
                warning_message: ['documents warning message'],
                warning_value: '/images/acyform/202306/1688008051lalocif816@edulena.com2023-06-29_030731am_POA-Cor.png'
              }
            ]
          }
        }
      ]
    })
  )
);
