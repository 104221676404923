import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { rest } from 'msw';

export const getEmptyAccount = rest.get(`${DOMAIN_CONFIG.api}/accounts`, (_, res, ctx) => res(
  ctx.status(200),
  ctx.json([])
));

export const getAccounts = rest.get(`${DOMAIN_CONFIG.api}/accounts`, (_, res, ctx) => res(
  ctx.status(200),
  ctx.json([
    {
      id: '0a0001e9-8363-1b83-8183-680e559f0005',
      name: 'FirstName LastName testA',
      type: 'personal',
      status: 'Incomplete',
      regulation: 'vfsc'
    },
    {
      id: '0a0001e9-8363-1b83-8183-680e559f0006',
      name: 'FirstName LastName testB',
      type: 'personal',
      status: 'Active',
      regulation: 'asic'
    }
  ])
));

export const getRejectAccounts = rest.get(`${DOMAIN_CONFIG.api}/accounts`, (_, res, ctx) => res(
  ctx.status(200),
  ctx.json([
    {
      id: '0a0001e9-8363-1b83-8183-680e559f0005',
      name: 'FirstName LastName testA',
      type: 'personal',
      status: 'Active',
      regulation: 'vfsc'
    },
    {
      id: '0a0001e9-8363-1b83-8183-680e559f0005',
      name: 'FirstName LastName testB',
      type: 'personal',
      status: 'Active',
      regulation: 'asic'
    },
    {
      id: '0a422843-89aa-1a4a-8189-af8a79360004',
      name: 'FirstName Joint',
      type: 'joint',
      status: 'Reject',
      regulation: 'asic'
    }
  ])
));
